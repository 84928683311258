import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'wouter';
import {
  deleteAdmin,
  IAdminTotals,
  IAdminUserGridItem,
  useAdminListTotals,
  useCurrentAdmin,
} from '../../shared/appUIFramework/appBackend/useAdminsInfinite';
import { AdminUserRole, getUserRoleTranslationKey } from '../../shared/appUIFramework/appBackend/useProfileDetails';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import { canDeleteAdminUser, canEditAdminUser } from '../../shared/appUIFramework/appPermisions/permissionActions';
import { UserPermission } from '../../shared/appUIFramework/appPermisions/userPermission';
import { SortStringOptions } from '../../shared/appUIFramework/hooks/useSortStringOptions';
import { IGridQueryParams } from '../systems/models/IGridQueryParams';
import AdminShell from './components/AdminShell';
import DeleteAdminConfirmationPopup from './components/DeleteAdminConfirmationPopup';
import DeleteAdminSuccessPopup from './components/DeleteAdminSuccessPopup';
import AppTablePage from '../../shared/appUIFramework/components/AppTablePage';
import './AdminPeople.scss';
import {
  AccountStatusUIState,
  applyFunctionalityLimitedStyles,
  useAccountInfo,
} from '../../shared/appUIFramework/appBackend/useAccountInfo';
import {
  handleAdminUserDeleteError,
} from '../../shared/appUIFramework/appBackend/errorHandling/handleAdminUserDeleteError';
import { Urls } from '../../shared/backend/urls';
import BulkDeleteUsersConfirmationPopup from '../systems/components/BulkDeleteUsersConfirmationPopup';
import BulkDeleteEntryUsersSuccessPopup from '../systems/components/BulkDeleteEntryUsersSuccessPopup';
import AppSystemErrorPopup from '../../shared/appUIFramework/appGenericErrorPopup/AppSystemErrorPopup';
import { ApiErrorType } from '../../shared/appUIFramework/appBackend/errorHandling/useSWRAndHandleErrors';
import { hasAnyOfPermissions } from '../../shared/appUIFramework/appPermisions/useAppPermissions';
import { useLogout } from '../../shared/appUIFramework/hooks/useLogout';

export function getDeleteAdminSuccessfulTextByRole(role: AdminUserRole) {
  return {
    [AdminUserRole.PaymentAdmin]: {
      header: 'DeleteUser_DeleteAUser_PaymentAdmin',
      subheader: 'Popup_UserDeleted_PaymentAdmin',
      subtext: 'Popup_UserHasBeenDeleted_PaymentAdmin',
    },
    [AdminUserRole.SiteAdmin]: {
      header: 'DeleteUser_DeleteAUser_SiteAdmin',
      subheader: 'Popup_UserDeleted_SiteAdmin',
      subtext: 'Popup_UserHasBeenDeleted_SiteAdmin',
    },
    [AdminUserRole.SystemAdmin]: {
      header: 'DeleteUser_DeleteAUser_SystemAdmin',
      subheader: 'Popup_UserDeleted_SystemAdmin',
      subtext: 'Popup_UserHasBeenDeleted_SystemAdmin',
    },
  }[role];
}

interface IAdminPeopleQueryParams extends IGridQueryParams {
  userRole?: AdminUserRole;
  nameSort?: SortStringOptions;
  siteSort?: SortStringOptions;
}

export default function AdminPeople() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { accountInfo } = useAccountInfo();
  const isAccountFunctionalityLimited = accountInfo.accountStatus === AccountStatusUIState.Limited;
  const { admin: currentlyLoggedInAdmin } = useCurrentAdmin();
  const logout = useLogout();

  return (
    <AdminShell activeTab="people">
      <AppTablePage<IAdminUserGridItem, IAdminPeopleQueryParams, IAdminTotals>
        searchText={t('SearchPeople')}
        normalModeActions={(
          <AppPermissionComponentGuard permissions={[UserPermission.CanCreateAdmins, UserPermission.CanCreateAdminsOfLowerRoles]}>
            {isAccountFunctionalityLimited
              ? (
                <span className="app-primary-button disabled-green" {...applyFunctionalityLimitedStyles(true)}>
                  {t('AddANewPerson')}
                </span>
              )
              : (
                <Link
                  to="/admin/people/0"
                  className="app-primary-button"
                >
                  {t('AddANewPerson')}
                </Link>
              )}
          </AppPermissionComponentGuard>
        )}
        inTabsShell
        table={{
          query: {
            itemsUrl: Urls.AdminsList,
            initialQueryParams: {
              search: '',
              nameSort: SortStringOptions.Ascending.toString() as any,
            },
            useTotals: (queryParams) => {
              const { adminTotals, mutate } = useAdminListTotals(queryParams);

              return {
                totals: adminTotals,
                totalCount: adminTotals?.totalAdmins ?? 0,
                reduceTotals: () => mutate({
                  totalAdmins: adminTotals!.totalAdmins - 1,
                }, { revalidate: false }),
                getTotalValues: (totals) => [
                  {
                    label: t('Info_TotalPeople'),
                    value: totals.totalAdmins,
                  },
                ],
              };
            },
          },
          remove: {
            bulkDelete: {
              ConfirmationPopup: BulkDeleteUsersConfirmationPopup,
              SuccessPopup: BulkDeleteEntryUsersSuccessPopup,
              ErrorPopup: AppSystemErrorPopup,
            },
            singleDelete: {
              ConfirmationPopup: ({ item: { name, userRole } }: { item: IAdminUserGridItem }) => (
                <DeleteAdminConfirmationPopup
                  admin={name}
                  role={userRole}
                />
              ),
              SuccessPopup: ({ item: { userRole } }: { item: IAdminUserGridItem }) => (
                <DeleteAdminSuccessPopup
                  role={userRole}
                />
              ),
              CustomErrorHandle: (error: ApiErrorType) => handleAdminUserDeleteError(error, 'DeleteUser_DeleteAUser_PaymentAdmin', t('ToDeleteUserCreateAnotherAdmin')),
            },
            requiredPermissions: [],
            customPermissionAction: (item) => (item
              ? canDeleteAdminUser(item)
              : hasAnyOfPermissions(UserPermission.CanEditAdmins, UserPermission.CanEditAdminsOfLowerRoles)),
            removeItemFromBackend: (itemId) => deleteAdmin(itemId),
            postDeleteAction: (item: IAdminUserGridItem) => {
              if (item.id === currentlyLoggedInAdmin?.id) {
                logout();
              }
            },
          },
          goToItem: (item) => {
            if (canEditAdminUser(item)) {
              setLocation(`/admin/people/${item.id}`);
            }
          },
          columns: [
            {
              title: t('Name'),
              getValue: (item) => item.name,
              queryParamSetting: {
                key: 'nameSort',
                type: 'sort',
                values: [
                  [SortStringOptions.Ascending, t('TableSort_ATOZ')],
                  [SortStringOptions.Descending, t('TableSort_ZTOA')],
                ],
              },
            },
            {
              title: t('EmailAddress'),
              getValue: (item) => item.email,
              isHiddenOnMobile: true,
            },
            {
              title: t('AccessLevel'),
              getValue: (item) => t(getUserRoleTranslationKey(item.userRole)),
              queryParamSetting: {
                key: 'userRole',
                type: 'filter',
                values: [
                  ['undefined', t('All')],
                  [AdminUserRole.SystemAdmin, t('SystemAdmin')],
                  [AdminUserRole.PaymentAdmin, t('PaymentAdmin')],
                  [AdminUserRole.SiteAdmin, t('SiteAdmin')],
                ],
              },
            },
            {
              title: t('System'),
              getValue: (item) => item.sites?.map((p) => p.systemName).join(', ') ?? '',
              queryParamSetting: {
                key: 'siteSort',
                type: 'sort',
                values: [
                  [SortStringOptions.Ascending, t('TableSort_ATOZ')],
                  [SortStringOptions.Descending, t('TableSort_ZTOA')],
                ],
              },
            },
          ],
        }}
      />
    </AdminShell>
  );
}

import { useEffect, useState } from 'react';
import { wsSubscribe } from '../../../backend/websockets/websockets';
import { Urls } from '../../../backend/urls';
import { useLogout } from '../../hooks/useLogout';

export default function AppUserDeletedLister() {
  const logout = useLogout();

  const [shouldLogoutOnNextAction, setShouldLogoutOnNextAction] = useState(false);

  useEffect(() => {
    if (shouldLogoutOnNextAction) {
      window.addEventListener('click', logout, { once: true });
    }
  }, [shouldLogoutOnNextAction]);

  useEffect(() => {
    let clearWsSubscribe = () => {
    };

    wsSubscribe(async () => {
      setShouldLogoutOnNextAction(true);
    }, Urls.AdminUsersNegotiate)
      .then((clearSub) => {
        clearWsSubscribe = clearSub;
      });

    return () => {
      clearWsSubscribe();
    };
  }, []);

  return <></>;
}
